<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          Yapılan Çekilişler
        </b-card-title>
      </b-card-header>
      <template v-if="dataList.length > 0">
        <b-table
          :items="dataList"
          :fields="fields"
          striped
        >
          <template #cell(title)="data">
            <div v-if="data.item.gcode">
              <small class="text-primary">{{ data.item.gcode }}</small>
            </div>
            {{ data.item.title }}
            <div v-if="data.item.awards">
              <small class="text-warning"><b>Ödüller: </b>{{ data.item.awards }}</small>
            </div>
          </template>
          <template #cell(created)="data">
            {{ moment(data.item.created).format('DD.MM.YYYY') }}
            <div>
              <small class="text-warning">{{ moment(data.item.created).format('HH:MM:SS') }}</small>
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              size="small"
              variant="primary"
              :to="'/twitch/giveaways/view/'+data.item.id"
            >
              Görüntüle
            </b-button>
          </template>
        </b-table>
        <BCardFooter>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </BCardFooter>
      </template>
      <b-alert
        v-else
        variant="info"
        show
        class="m-1"
      >
        <div class="alert-body text-center">
          <feather-icon icon="InfoIcon" />
          <div>
            Yapılmış veya tamamlanmış çekilişiniz bulunmuyor.
          </div>
        </div>
      </b-alert>
    </b-card>
  </div>
</template>
<script>
import {
  BPagination, BCard, BCardTitle, BCardHeader, BButton, BAlert, BTable, BCardFooter,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const tableName = 'giveaways'

export default {
  name: 'GiveAways',
  components: {
    BAlert,
    BPagination,
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BTable,
    BCardFooter,
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.title AS title`,
          `${tableName}.gcode AS gcode`,
          `${tableName}.awards AS awards`,
          `${tableName}.created AS created`,
        ],
        limit: this.$store.state.app.perPage,
        start: 0,
      },
      fields: [
        {
          key: 'title',
          label: 'Başlık',
        },
        {
          key: 'created',
          label: 'Tarih',
          thStyle: { width: '200px' },

        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '200px' },
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['giveaways/dataList']
    },
    saveStatus() {
      return this.$store.getters['giveaways/dataSaveStatus']
    },
    dataCount() {
      return this.$store.getters['giveaways/dataCounts']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getData()
    // },
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('giveaways/getDataList')
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
